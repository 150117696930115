import React from "react"
import elder from "../images/elder.svg"
import door from "../images/door.svg"
import hands from "../images/hands.svg"
import Fade from "react-reveal/Fade"
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

function Reasons(props) {
  return (
    <StaticQuery
      query={graphql`
        query BulletPointsTemplate {
          markdownRemark(frontmatter: { templateKey: { eq: "bulletPoints" } }) {
            frontmatter {
              bulletPoint1 {
                image
                title
                content
              }
              bulletPoint2 {
                image
                title
                content
              }
              bulletPoint3 {
                image
                title
                content
              }
            }
          }
        }
      `}
      render={data => {
        const { markdownRemark } = data
        return (
          <Fade distance="100px" top cascade>
            <div
              className="reasons"
              style={
                props.bottom ? { paddingBottom: 0, borderBottom: "none" } : {}
              }
            >
              <div>
                <img
                  style={{ position: "relative", bottom: "-20px" }}
                  src={markdownRemark.frontmatter.bulletPoint1.image}
                />
                <h2>{markdownRemark.frontmatter.bulletPoint1.title}</h2>
                <ReactMarkdown
                  source={markdownRemark.frontmatter.bulletPoint1.content}
                />
              </div>
              <div>
                <img src={markdownRemark.frontmatter.bulletPoint2.image} />
                <h2>{markdownRemark.frontmatter.bulletPoint2.title}</h2>
                <ReactMarkdown
                  source={markdownRemark.frontmatter.bulletPoint2.content}
                />
              </div>
              <div>
                <img src={markdownRemark.frontmatter.bulletPoint3.image} />
                <h2>{markdownRemark.frontmatter.bulletPoint3.title}</h2>
                <ReactMarkdown
                  source={markdownRemark.frontmatter.bulletPoint3.content}
                />
              </div>
            </div>
          </Fade>
        )
      }}
    />
  )
}

export default Reasons
